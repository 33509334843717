<template>
  <div class="service_list_box">
    <div class="service_item_list">
      <div
        class="col-md-12"
      >
        <div class="sorting_view_main_box">
          <div class="title_view">
            <h4 class="search_result_text">
              Recommendation History({{ recommendRows }})
            </h4>
            <!-- v-b-tooltip.hover.top="'sort alphabetically'" -->
            <div

              class="sort_item_box_view"
              @click="sortData"
            >
              <img
                v-if="sort_type === 'DESC'"
                :src="SortDown"
                alt="sort"
              >
              <img
                v-if="sort_type === 'ASC'"
                v-b-tooltip.hover.top="'sort alphabetically'"
                :src="SortUp"
                alt="sort"
              >
            </div>
          </div>

          <div class="search-input-view">
            <b-form-input
              id="h-search-keyword"
              v-model="searchTerm"
              type="text"
              placeholder="search recommendation history"
              class="form-control"
              style="min-width: 450px;"
            />
          </div>
        </div>
        <div class="row mt-2 site_item_view_box">
          <div
            v-for="(item, index) in recommendations"
            :key="index"
            class=" col-12 col-md-6 col-lg-4 mb-2"
            @click="showRecommendDetails(item)"
          >
            <div class="service_card_box">
              <div class="service_image_box">
                <div
                  class="service_image"
                  v-lazy:background-image="getImage(item.cover)"
                />
              </div>

              <div class="card_details">
                <div class="tag-view">
                  <!-- <span>{{ item.state }}</span> -->
                  <span>Recommend to: {{ item.contact_name }}</span>
                </div>

                <h3 class="list_heading">
                  {{ item.name | setTruncate(38, "...") }}
                </h3>

                <blockquote class="blockquote pl-1 border-left-success border-left-3">
                  <p>{{ item.message_sent | setTruncate(85, "...") }}</p>
                  <footer class="blockquote-footer text-left">
                    Message Sent
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div
        v-if="recommend_page > 1 || recommendations.length > 8"
        class="pagination_box mt-3"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="recommendRows"
          :per-page="perPage"
          size="lg"
          align="center"
          @change="onRecommendPageChange"
        />
      </div>
    </div>

  </div>
</template>

<script>

import {
  BPagination,BFormInput,
} from 'bootstrap-vue'
import SortDown from '../../../assets/images/icons/sortdown.png'
import SortUp from '../../../assets/images/icons/sortup.png'

export default {
  components: {
    BPagination,
    BFormInput,
  },
  data() {
    return {
      SortUp,
      SortDown,
      recommend_page: 0,
      perPage: 8,
      searchTerm: '',
      sort_type: 'DESC',
    }
  },
  computed: {
    recommendations() {
      const items = this.$store.state.recommend.recommendations
      return items.filter(item => item.name.toLowerCase().includes(this.searchTerm.toLowerCase()))
    },
    recommendRows() {
      return this.$store.state.recommend.total
    },
    is_recommendation() {
      return this.$store.state.recommend.is_recommendation
    },
    search_result: {
      get() {
        return this.$store.state.businessProfile.search_result_text
      },
    },
  },
  mounted() {
    this.getRecommendations()
  },
  methods: {
    getRecommendations() {
      this.$store.dispatch('businessProfile/updateMessage', '')
      // this.$store.dispatch('recommend/updateRecommendationData', true)
      // this.$store.dispatch('businessProfile/updateSearchResult', 'Recommendation History')
      this.$store.dispatch('recommend/getRecommendations', { page: 1, per_page: this.perPage })
    },
    onRecommendPageChange(page) {
      this.recommend_page = page
      return this.$store.dispatch('recommend/getRecommendations', { page, per_page: this.perPage })
    },
    sortData() {
      if (this.sort_type === 'DESC') {
        this.sort_type = 'ASC'
      } else if (this.sort_type === 'ASC') {
        this.sort_type = 'DESC'
      }
      this.$store.dispatch('recommend/sortRecommendation', { data: this.recommendations, sort_type: this.sort_type })
    },
    // getImage(image) {
    //   if (image !== null || image !== '') {
    //     return `background: url(${process.env.VUE_APP_STORAGE_PATH + image})`
    //   }
    //   return image
    // },
    getImage(image) {
      return process.env.VUE_APP_STORAGE_PATH + image
    },
    showRecommendDetails(item) {
      localStorage.setItem('gts_profile_id', item.id)
      this.$store.dispatch('businessProfile/getSingleBusinessProfile', item.id)
      const slug = item.name.replaceAll(' ', '-').toLocaleLowerCase()
      this.$router.push(`/services/${slug}`)
    },
  },
}
</script>

<style scoped>

</style>
